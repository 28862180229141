import Footer from "./components/base/Footer";
import Header from "./components/base/Header";
import Home from "./components/home/Home";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Home />
      </div>
      <Footer />
    </div>
  );
}

export default App;
