export default function Testimony({}) {
  return (
    <div className="w-full px-5 py-20 bg-green-300">
      <div className="mx-auto max-w-2xl flex flex-col">
        <span className="text-xl font-semibold text-center">
          I'm extremely grateful for the exceptional service provided by
          greenvally. They helped me find my dream home in Doha effortlessly.
          Their professionalism, expertise, and attention to detail made the
          process smooth and enjoyable. Thank you for making my real estate
          experience a positive one!
        </span>
        <span className="mt-5  font-light text-center">- Emily</span>
      </div>
    </div>
  );
}
