import { useState } from "react";

import {
  FacebookIcon,
  InstagramIcon,
  MenuIcon,
  TwitterIcon,
} from "../common/AppIcons";
import SidebarMenu from "./Menu";
import { companyData } from "../../data/companyData";

import greenVallyLogo from "../../assets/green_valley.png";

export default function Header({ }) {
  const [menu, set_menu] = useState(false);

  return (
    <header className="text-gray-600 body-font bg-white shadow-lg">
      <div className="md:hidden flex flex-row justify-between items-center w-full py-5 px-5">
        <a
          href="/"
          className="flex title-font font-medium items-center text-gray-900"
        >
          <img src={greenVallyLogo} className="h-12" />
        </a>
        <div
          onClick={() => {
            set_menu(true);
          }}
        >
          <MenuIcon
            className={
              "h-5 w-5 cursor-pointer text-gray-800 hover:text-gray-500"
            }
          />
        </div>
      </div>
      <SidebarMenu
        className="md:hidden"
        show={menu}
        set_show={() => {
          set_menu(false);
        }}
      />
      <div className="hidden md:flex container mx-auto max-w-7xl flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          href="/"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <div className="ml-3 text-xl">
            <img src={greenVallyLogo} className="h-12" />
          </div>
        </a>
        <nav className="text-green-500 md:ml-auto flex flex-wrap items-center gap-7 text-base justify-center">
          <a
            href={companyData.facebook}
            className="text-gray-800 hover:text-gray-600"
          >
            <FacebookIcon className="h-6 " />
          </a>
          <a
            href={companyData.instagram}
            className="bg-gray-800 hover:bg-gray-600 rounded-full p-0.5"
          >
            <InstagramIcon className="h-4" />
          </a>
          <a
            href={companyData.twitter}
            className="mr-5 text-gray-800 hover:text-gray-600"
          >
            <TwitterIcon className="h-4" />
          </a>
        </nav>
        <a
          href={"tel:" + companyData.phone}
          className="border-2 text-indigo-700 border-indigo-700 transform duration-300 hover:scale-110 hover:text-green-500 hover:bg-indigo-700 py-1 px-5 rounded text-base"
        >
          Call Us
        </a>
      </div>
    </header>
  );
}
