export default function Featured({}) {
  return (
    <section className="text-gray-600 bg-green-400 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex items-center justify-center w-full">
          <span className="text-gray-800 font-semibold text-4xl">Featured</span>
        </div>

        <div className="mt-4 flex flex-wrap">
          {[
            {
              image: "/living_room_2.webp",
              title: "Property Management",
              body: "Efficient management solutions for your valuable assets.",
            },
            {
              image: "/home.webp",

              title: "Real Estate Consultancy",
              body: "Expert advice to help you make informed decisions in the market.",
            },
            {
              image: "home_2.jpeg",
              title: "Property Valuation",
              body: "Accurate property valuations to determine market worth.",
            },
          ].map((ele, ele_index) => (
            <div
              key={ele_index}
              className="p-4 md:w-1/3 sm:mb-0 mb-6 flex flex-col items-center"
            >
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={ele.image}
                />
              </div>
              <h2 className="text-center text-2xl font-medium title-font text-gray-900 mt-5">
                {ele.title}
              </h2>
              <p className="text-sm text-center leading-relaxed mt-2">
                {ele.body}
              </p>
              <button className="mt-3 border-2 text-indigo-600 border-indigo-600 transform hover:scale-110 duration-300 hover:text-green-400 hover:bg-indigo-600 py-1 px-3 rounded text-base">
                More info
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
