export default function Hero({}) {
  return (
    <div className="relative h-screen">
      <img className="absolute inset-0 h-full w-full" src="/living_room.jpeg" />
      <div className="absolute z-10 inset-0 bg-green-400 bg-opacity-50 flex flex-col items-center justify-center">
        <div className="text-gray-700 body-font container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div className="text-center lg:w-2/3 w-full max-w-lg">
            <h1 className="title-font sm:text-5xl text-4xl mb-4 font-medium text-gray-900">
              Discover your dream home
            </h1>
            <p className="mb-8 leading-relaxed text-lg">
              Find the perfect property in Doha with greenvally's real estate
              services
            </p>
            <button className="border-2 text-indigo-900 border-indigo-900 transform hover:scale-110 duration-300 hover:text-green-400 hover:bg-indigo-800 hover:border-indigo-800 py-1 px-3 rounded text-base mt-4 md:mt-0">
              Explore now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
