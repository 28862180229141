import ContactUs from "./components/ContactUs";
import Featured from "./components/Featured";
import Hero from "./components/Hero";
import Team from "./components/Team";
import Testimony from "./components/Testimony";

export default function ({}) {
  return (
    <div>
      <Hero />
      <Testimony />
      <Featured />
      <Team />
      <ContactUs />
    </div>
  );
}
