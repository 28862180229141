export const FacebookIcon = ({ className, style }) => (
  <svg
    className={className ?? "h-5 text-blue-400 "}
    style={style}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z" />
  </svg>
);

export const InstagramIcon = ({ className, style }) => (
  <svg
    className={className ?? "h-5 text-blue-400 "}
    style={style}
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z" />
  </svg>
);

export const TwitterIcon = ({ className, style }) => (
  <svg
    className={className ?? "h-5 text-blue-400 "}
    style={style}
    fill="currentColor"
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8181 2.1461C18.1356 2.44856 17.4032 2.65368 16.6336 2.74525C17.4194 2.27474 18.0208 1.52844 18.3059 0.641891C17.5689 1.07762 16.7553 1.39401 15.8885 1.56553C15.1943 0.825012 14.2069 0.36377 13.1118 0.36377C11.0108 0.36377 9.30722 2.06731 9.30722 4.16719C9.30722 4.46501 9.34083 4.75589 9.40574 5.03404C6.24434 4.87525 3.44104 3.36062 1.56483 1.05907C1.23686 1.61998 1.05028 2.27356 1.05028 2.97122C1.05028 4.29119 1.72243 5.45586 2.74225 6.13725C2.11877 6.11641 1.53237 5.94489 1.01901 5.6598V5.70732C1.01901 7.54992 2.33086 9.08774 4.07031 9.43774C3.75161 9.5235 3.41555 9.57101 3.06789 9.57101C2.82222 9.57101 2.58464 9.54668 2.35171 9.50032C2.8361 11.0126 4.24067 12.1124 5.90483 12.1426C4.6034 13.1624 2.96243 13.7685 1.1801 13.7685C0.873008 13.7685 0.570523 13.7499 0.272705 13.7163C1.95655 14.7976 3.95561 15.428 6.10416 15.428C13.1026 15.428 16.928 9.63128 16.928 4.6041L16.9153 4.11159C17.6627 3.57847 18.3094 2.90865 18.8181 2.1461Z"
    />
  </svg>
);

export const MenuIcon = ({ className }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    className={`${className ?? "h-5 text-blue-400 "}`}
    viewBox="0 0 20 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z" />
  </svg>
);

export const ExperianceIcon = ({ className }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    className={`${className ?? "h-5 text-blue-400 "}`}
    viewBox="0 0 256 256"
    enableBackground="new 0 0 256 256"
  >
    <g>
      <g>
        <path d="M128,10C62.9,10,10,62.9,10,128c0,65.1,52.9,118,118,118c65.1,0,118-52.9,118-118C246,62.9,193.1,10,128,10z M158.1,179.4c-4,4.7-8.7,8.7-13.7,12.4c-4,2.7-8.2,5.5-12.4,7.9c-2.7,1.7-5.7,1.5-8.7-0.2c-7-4.5-13.7-9.4-19.9-15.1c-1.7-1.7-3.5-3.5-5-5c-3.2-3.2-4-6.7-3.5-11.2c3.5-23.4,9.2-46.5,15.1-69.3c0.5-1.5,0.7-3.2,1.5-4.7c0.5-1.3,0.2-2.2-0.5-3.2c-4.2-6-7.2-12.7-9.7-19.4c-2.5-7,1.7-13.7,8.5-14.4c5.7-0.5,11.4-1.3,17.1-2c0.2,0,0.7,0,1,0c6.2,0,12.4,1,18.6,2c6.2,1.3,9.9,7.2,8.2,13.2c-2.2,7.4-5.5,14.4-10.2,20.9c-0.5,0.7-0.7,1.5-0.5,2.7c4,15.4,7.9,31,11.4,46.7c2.2,9.4,3.7,19.1,5.2,28.8C161.8,173.2,160.5,176.5,158.1,179.4z" />
        <path
          fill="#000000"
          d="M152.3,151.6c-3.7-19.1-8.7-37.8-13.9-56.6c-0.5,0-1,0-1.5,0c-6.2,0-12.2,0-18.4,0c-1,0-1.5,0.2-1.7,1.3c-7,24.3-12.9,48.7-16.6,73.8c-0.2,2.2,0.2,4,1.7,5.5c6.7,7.4,14.7,13.4,23.1,18.6c2,1.3,3.7,1,5.2,0c3.5-2.2,7-4.7,10.4-7.2c5-3.5,9.4-7.7,13.7-12.2c1-1.3,1.7-2.5,1.5-4C154.6,164.3,153.6,157.8,152.3,151.6z"
        />
        <path
          fill="#000000"
          d="M116.6,88.7c3.5,0,7,0,10.7,0c3.5,0,7,0,10.7,0c0.5,0,1.3-0.2,1.7-0.7c4.2-5.7,7.2-12.2,9.4-18.9c1-2.7-0.5-5.5-3.5-6c-5.2-1-10.4-1.7-15.7-2c-6.7-0.5-13.2,0.7-19.6,2c-3.5,0.7-5,3.2-4,6.5c2,6.5,4.7,12.7,8.7,18.4C115.3,88.2,116.1,88.7,116.6,88.7z"
        />
      </g>
    </g>
  </svg>
);

export const HomeDollorIcon = ({ className }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    className={`${className ?? "h-5 text-blue-400 "}`}
    viewBox="0 0 612 612"
  >
    <g>
      <path
        d="M602.349,390.517c-14.217-22.021-40.281-31.476-64.411-25.85V193.168h17.652c0.053,0.014,0.149,0,0.176,0
            c14.486,0,26.229-11.74,26.229-26.214c0-9.456-4.992-17.734-12.485-22.346L387.757,8.561c-9.318-6.966-22.115-6.979-31.448,0
            l-73.501,55.011V51.493c0-14.486-11.74-26.229-26.214-26.229h-24.266c-14.474,0-26.214,11.74-26.214,26.229v69.5l-33.356,24.956
            c-9.049,6.777-12.728,18.572-9.17,29.284c3.558,10.712,13.595,17.95,24.888,17.95h17.638v161.07h-12.417
            c-11.471,0-22.075,3.383-31.206,8.927c-5.831-27.269-30.015-47.733-59.001-47.733H60.368C27.038,315.446,0,342.472,0,375.813V548.3
            c0,33.342,27.038,60.368,60.368,60.368h43.122c29,0,53.185-20.466,59.001-47.734c9.131,5.545,19.735,8.927,31.206,8.927h114.269
            h109.968c11.605,0,22.98-3.341,32.734-9.645l133.679-86.243C612.359,455.89,620.434,418.529,602.349,390.517z M81.928,425.4
            c-20.249,0-36.657-16.408-36.657-36.657s16.408-36.656,36.657-36.656s36.657,16.407,36.657,36.656
            C118.585,408.994,102.177,425.4,81.928,425.4z M400.132,449.125h-31.787v-34.492c0-3.882-0.433-7.642-1.15-11.322h103.962
            L400.132,449.125z"
      />
    </g>
  </svg>
);

export const HomeDollorIcon2 = ({ className }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    className={`${className ?? "h-5 text-blue-400 "}`}
    viewBox="0 0 222.48 222.48"
  >
    <path
      d="M195.657,85.884v-45.28h-30v18.075L111.074,8.877L0,109.604h25.657v104h171v-104h25.823L195.657,85.884z M132.316,164.783
        c-3.629,3.222-8.131,5.44-13.381,6.589l0.087,11.717l-16.24,0.041l-0.076-11.716c-4.879-1.122-8.403-3.432-12.604-6.908
        c-5.586-4.812-8.498-10.434-8.684-16.734l-0.003-0.107l0.395-5.054l15.644-0.057l0.115,3.09c0.065,4.171,1.277,7.2,3.705,9.304
        c2.438,2.19,4.541,3.29,9.344,3.29c4.729-0.038,8.4-1.182,10.898-3.4c2.399-2.115,3.563-5.068,3.563-9.034
        c0-3.149-1.063-5.457-3.348-7.262c-2.606-2.044-7.298-3.872-13.938-5.428c-8.055-1.874-14.232-4.784-18.36-8.649
        c-4.402-4.177-6.647-9.432-6.683-15.628c-0.046-7.099,2.723-13.007,8.229-17.559c3.226-2.682,7.014-4.567,11.277-5.617l-0.078-10.25
        l16.234-0.112l0.043,9.9c4.764,0.933,7.878,2.854,11.421,5.74c5.291,4.426,8.167,10.484,8.567,18.023l0.115,3.304l-15.713,0.098
        l-0.432-2.68c-0.53-3.953-1.785-6.797-3.738-8.512c-2.068-1.813-3.515-2.568-7.112-2.568h-0.229c-4.133,0-7.438,0.961-9.578,2.788
        c-2.072,1.733-3.019,4.031-2.992,7.248c0,2.698,0.903,4.744,2.763,6.273c1.504,1.267,4.734,3.092,11.69,4.689
        c9.029,2.122,15.938,5.29,20.544,9.418c4.846,4.384,7.3,9.933,7.3,16.497C141.109,153.299,138.166,159.785,132.316,164.783z"
    />
  </svg>
);
