import {
  ExperianceIcon,
  HomeDollorIcon,
  HomeDollorIcon2,
} from "../../common/AppIcons";

export default function Team({}) {
  return (
    <div className="bg-green-300 w-full">
      <div className="mx-auto max-w-7xl px-5 flex flex-col gap-10">
        <section className="text-gray-600 body-font">
          <div className="container py-24 mx-auto flex flex-wrap">
            <div className="flex flex-wrap lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
              <h3 className="title-font font-semibold text-4xl mb-5 text-gray-900">
                Meet the team
              </h3>
              <div className="leading-relaxed">
                At greenvally, our real estate team is comprised of dedicated
                professionals who are passionate about helping clients find
                their dream homes. With years of experience in the industry, our
                team possesses extensive knowledge of the local market and a
                strong commitment to customer satisfaction. From our friendly
                agents to our skilled negotiators, each member of our team is
                dedicated to providing personalized service and ensuring a
                smooth and successful real estate experience for every client.
                Get to know our team and let us guide you through your real
                estate journey.
              </div>
            </div>
            <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
              <img
                className="object-cover object-center w-full h-full"
                src="/team.jpeg"
                alt="stats"
              />
            </div>
          </div>
        </section>
        <div className="mb-10 w-full flex flex-row items-center justify-center gap-20">
          {[
            {
              label: "10+ years of experience",
              icon: ExperianceIcon,
            },
            {
              label: "Expert team of realtors",
              icon: HomeDollorIcon,
            },
            {
              label: "Wide range of property options",
              icon: HomeDollorIcon2,
            },
          ].map((ele, ele_index) => (
            <div key={ele_index} className="flex flex-col items-center gap-4">
              <ele.icon className="h-7" />
              <span className="text-xl font-semibold">{ele.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
