import React from "react";
import { Transition } from "@headlessui/react";

import { FacebookIcon, InstagramIcon, TwitterIcon } from "../common/AppIcons";
import { companyData } from "../../data/companyData";

import greenVallyLogo from "../../assets/green_valley.png";

export default function SidebarMenu({ show, set_show, className }) {
  return (
    <div
      onClick={set_show}
      className={
        "fixed inset-0 bg-gray-200 bg-opacity-50 text-gray-700 z-20 " +
        (show ? "" : "hidden ") +
        className
      }
    >
      <Transition
        show={show}
        className="absolute right-0 shadow-md"
        enter="transition-all transform duration-200"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition-all transform duration-200"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="mx-auto h-screen flex flex-wrap flex-col items-center bg-green-600 text-right"
        >
          <div className="h-full container mx-auto max-w-7xl flex flex-wrap px-10 py-5 flex-col items-center">
            <a
              href="/"
              className="flex title-font font-medium items-center text-gray-900"
            >
              <img src={greenVallyLogo} className="h-12" />
            </a>
            <nav className="mt-auto text-green-500 md:ml-auto flex flex-wrap items-center gap-4 text-base justify-center">
              <a
                href={companyData.facebook}
                className="text-gray-800 hover:text-gray-600"
              >
                <FacebookIcon className="h-6 " />
              </a>
              <a
                href={companyData.instagram}
                className="bg-gray-800 hover:bg-gray-600 rounded-full p-0.5"
              >
                <InstagramIcon className="h-4" />
              </a>
              <a
                href={companyData.twitter}
                className="mr-5 text-gray-800 hover:text-gray-600"
              >
                <TwitterIcon className="h-4" />
              </a>
            </nav>
            <a
              href={"tel:" + companyData.phone}
              className="border-2 text-indigo-300 border-indigo-300 py-1 px-3 rounded text-base mt-4 md:mt-0"
            >
              Call Us
            </a>
          </div>
        </div>
      </Transition>
    </div>
  );
}
