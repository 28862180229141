import { companyData } from "../../data/companyData";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "../common/AppIcons";
import greenVallyLogo from "../../assets/green_valley.png";

export default function Footer({ }) {
  return (
    <footer className="flex flex-col">
      <div className="relative w-full bg-green-400 pb-6">
        <div className="absolute inset-0 w-full h-full">
          <img
            src="/doha.webp"
            className="w-full z-10"
            style={{ minHeight: "200px" }}
          />
        </div>
        <div className="relative inset-0 w-full z-20">
          <div className="mx-auto px-5 max-w-7xl flex justify-start">
            <a
              href={companyData.googleMapLink}
              target="_blank"
              className="mt-10 sm:mt-12 md:mt-16 mlg:mt-20 w-full rounded-md py-7 px-9 bg-green-600 hover:bg-green-700 flex flex-col gap-2 items-start"
            >
              <span className="text-xl font-semibold"> Location</span>
              <iframe
                className="w-full h-96"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3595.6711473346936!2d51.49297327539788!3d25.682193777401945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDQwJzU1LjkiTiA1McKwMjknNDQuMCJF!5e0!3m2!1sen!2sin!4v1693241516564!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </a>
          </div>
        </div>
      </div>
      <div className="py-12 text-gray-600 body-font bg-white">
        <div className="mx-auto px-5 max-w-7xl flex justify-start">
          <div className="flex flex-col items-center gap-3">
            <a href="/" className="title-font font-medium text-gray-900">

              <img src={greenVallyLogo} className="h-12" />
            </a>
            <nav className="text-green-500 flex flex-wrap items-center gap-4 text-base justify-center">
              <a
                href={companyData.facebook}
                className="text-gray-800 hover:text-gray-600"
              >
                <FacebookIcon className="h-6 " />
              </a>
              <a
                href={companyData.instagram}
                className="bg-gray-800 hover:bg-gray-600 rounded-full p-0.5"
              >
                <InstagramIcon className="h-4" />
              </a>
              <a
                href={companyData.twitter}
                className="text-gray-800 hover:text-gray-600"
              >
                <TwitterIcon className="h-4" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
